export default {
  autobotReady: `Наш помощник проведет Вас по 3 основным шагам для настройки автоответчика. Вы в любой момент можете прервать настройку и затем продолжить ее с того места на котором остановились.`,
  analyticsReady: `Аналитика будет готова к работе, когда все пункты, перечисленные ниже, будут настроены.
   Вы в любой момент можете прервать настройку и затем продолжить её с того места, на котором остановились.`,
  supplyPlanningReady: `Планирование поставок будет доступно, когда все пункты, перечисленные ниже, будут
  настроены. Вы в любой момент можете прервать настройку и затем продолжить ее с того места, на котором остановились`,
  createApi: `Для настройки продавца вам потребуются ключи API, которые нужно предварительно
   сгенерировать в своём личном кабинете Wildberries. С инструкцией по получению ключей можно ознакомиться `,
  firstStepDone:
    'Для настройки продавца вам потребуются ключи API, которые нужно предварительно сгенерировать в своем личном кабинете Wildberries. С инструкцией по получению ключей можно ознакомиться здесь.',

  modules: {
    autobot: 'Автобот',
    analytics: 'Аналитика',
    supplyPlanning: 'Планирование поставок',
    CRM: 'CRM',
  },
  statuses: {
    pending: 'Не настроено.',
    inProgress: 'Настройка не завершена.',
    done: 'Настроено, проверьте настройки.',
  },
  mainHint: `Наш помощник проведёт вас по всем основным настройкам подключённых модулей,
   подскажет, где они находятся, и проверит, всё ли настроено для начала работы`,
  customize: 'Настроить',
  mainTitle: 'Помощник',
  pass: 'Пропустить',
  next: 'Далее',
  back: 'Назад',
  autobot: {
    title: 'Настройка Автоответов на отзывы',
    finalTitle: 'УРА!!! Автобот готов к работе!',
    link: 'здесь.',
    configureSeller: 'Настроить продавца',
    needsConfigure: 'Необходима настройка',
    configured: 'Настроен',
    configureTemplate: 'Настроить шаблон для автоматических ответов',
    configureTask: 'Настроить регулярный запуск Автоответчика',
    startConfigure: 'Начать настройку',
    continueConfigure: 'Продолжить настройку',
    done: 'Готово',
    sellerTour: {
      title: 'Настройка продавца',
      step1text: 'Нажмите "Далее", чтобы выбрать в главном меню пункт "Продавцы". Вы увидите список всех ваших продавцов.',
      step2text: 'Нажмите "Далее", чтобы перейти к созданию нового продавца',
      step2alert: `Если нужный продавец уже есть в списке - пропустите этот
      пункт или закройте диалог и продолжите настройку продавца самостоятельно.`,
      step3text: 'Продавец успешно создан!',
      step3text2: 'Нажмите “Далее”, чтобы увидеть ваши отзывы в ЛК Sales Area',
      stepReviewsTitle: 'Страница отзывы',
      stepReviewsText: 'На странице “Отзывы WB” вы можете просматривать ваши отзывы и отвечать на них вручную.',
      stepReviewsText2: 'Нажмите “Далее”, чтобы перейти к настройке шаблона для автоматических ответов.',
    },
    templateTour: {
      title: 'Настройка шаблона',
      step1text: 'Нажмите "Далее", чтобы выбрать в главном меню пункт "Шаблоны ответов". Вы увидите список всех ваших шаблонов.',
      step2text: 'Нажмите "Далее", чтобы перейти к созданию нового шаблона для автоматического ответа',
      step2alert: 'Если нужный шаблон уже есть в списке - пропустите этот пункт или закройте диалог и продолжите настройку шаблона самостоятельно.',
      step3text: 'Шаблон успешно создан!',
      step3text2:
        'Нажмите “Далее”, чтобы проверить, как он сработает на ваших отзывах. Убедитесь, что шаблон находится в стостоянии “Включен”, иначе отзывы не будут обработаны.',
      stepFinalText: 'Нажмите кнопку “Запустить Автоответ”, чтобы разово запустить автоответчик и проверить работу шаблона.',
      stepFinalTitle: 'Проверка Шаблона',
      stepSwitcherText: 'Необходимо включить шаблон',
      stepFinalReviews: 'Обработанные отзывы',
      stepFinalReviewsText:
        'На вкладке “Обработанные” вы сможете просмотреть все отвеченные отзывы. Отзывы, отвеченные автоматически, имеют статус “Автоответ”. Нажмите “Далее”, чтобы настроить запуск автоответчика на определенное время.',
    },
    notificationsTour: {
      title: 'Настройка назначенного задания',
      step1text: 'Нажмите "Далее", чтобы выбрать в главном меню пункт "Задания". Вы увидите список всех ваших Заданий.',
      step2text: 'Нажмите "Далее", чтобы перейти к созданию нового задания для запуска Автобота',
      step2alert: 'Если нужное задание есть в списке - пропустите этот пункт или закройте диалог и продолжите настройку задания самостоятельно.',
      step3text1: 'Задание успешно создано!',
      step3text2: 'Нажмите "Далее", чтобы перейти к списку настроек и проверить, что все настройки выполнены.',
    },
  },
  analytics: {
    title: 'Настройка Аналитики',
    configureSeller: 'Настроить продавца',
    configureDone1: 'Аналитика готова к работе!',
    configureDone2: 'Контролируйте статистику в меню "Аналитика".',
    sellerTour: {
      title: 'Настройка продавца',
      step1text: 'Нажмите "Далее", чтобы выбрать в главном меню пункт "Продавцы". Вы увидите список всех ваших продавцов.',
      step2text: 'Нажмите "Далее", чтобы перейти к созданию нового продавца',
      step2alert: `Если нужный продавец уже есть в списке - пропустите этот
      пункт или закройте диалог и продолжите настройку продавца самостоятельно.`,
      step3text: 'Продавец успешно создан!',
      step3text2: 'Нажмите "Далее", чтобы перейти к списку настроек и проверить что все настройки выполнены.',
    },
  },
  supplyPlanning: {
    title: 'Настройка планирования поставок',
    link: 'здесь.',
    configureSeller: 'Настроить продавца',
    configureWidberriesWarehouse: 'Настроить склады Wildberries',
    needsConfigure: 'Требуется настройка',
    configured: 'Настроен',
    startConfigure: 'Начать настройку',
    continueConfigure: 'Продолжить настройку',
    configureDone1: 'Для планирования поставок все готово!',
    configureDone2: 'Проверьте, что настройки выполнены для всех нужных вам продавцов и складов.',
    done: 'Готово',
    sellerTour: {
      title: 'Настройка продавца',
      step1text: 'Нажмите "Далее", чтобы выбрать в главном меню пункт "Продавцы". Вы увидите список всех ваших продавцов.',
      step2text: 'Нажмите "Далее", чтобы перейти к созданию нового продавца',
      step2alert: `Если нужный продавец уже есть в списке - пропустите этот
      пункт или закройте диалог и продолжите настройку продавца самостоятельно.`,
      step3text: 'Продавец успешно создан!',
      step3text2: 'Нажмите "Далее", чтобы перейти к настройке складов Wildberries.',
    },
    wildberriesWarehousesTour: {
      title: 'Настройка склада Wildberries',
      step1text: 'Нажмите "Далее", чтобы выбрать в главном меню пункт "Склады Wildberries". Вы увидите список  используемых вами складов.',
      step1_1alert1: `Пока нет данных ни по одному складу настройка невозможна. Проверьте использование складов в личном кабинете
       Wildberries.`,
      step2text: `Нажмите "Далее", чтобы перейти к настройке первого склада в списке. Затем вы можете самостоятельно настроить остальные
      склады.`,
      step2alert1: `Нужно указать планируемый вами срок оборачиваемости склада. Планируемое время доставки товара до этого склада из
      вашей практики. И включить использование склада для планирования поставок.`,
      step2alert2: 'Обратите внимание! Склады настраиваются отдельно для каждого продавца.',
      step3text: 'Склад успешно настроен!',
      step3text2: 'Нажмите "Далее", чтобы посмотреть результаты планирования поставок для настроенных складов.',
    },
    supplyManagingTour: {
      title: 'Управление планированием поставок',
      step1text: `Нажмите "Далее", чтобы выбрать в главном меню пункт "Планирование поставок". Вы увидите список товаров,
      по которым есть история движения по настроенным складам.`,
    },
    preFinaleTour: {
      title: 'Настройка планирования поставок',
      step1text1: 'Для планирования поставок все готово!',
      step1text2: 'Нажмите "Далее", чтобы перейти к списку настроек и проверить, что все настройки выполнены.',
    },
  },
  root: {
    title: 'Помощник',
    done: 'Хорошо',
    step1text: 'Помощник находится тут. Воспользуйтесь им при необходимости.',
  },
};
